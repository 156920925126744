import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sharing", "download", "downloadBipOptions"]

  showSharing() {
    this.sharingTarget.classList.remove("hidden")
    this.downloadTarget.classList.add("hidden")
  }

  showDownload() {
    this.downloadTarget.classList.remove("hidden")
    this.sharingTarget.classList.add("hidden")
  }

  downloadPdf({ params: { pdfBipUrl } }) {
    const checkedInputs = this.downloadBipOptionsTarget.querySelectorAll("input[name='download_bip_options']:checked")
    const downloadBipOptions = Array.from(checkedInputs).flatMap(({value}) => value ? [value] : [])
    if (downloadBipOptions.length > 0) {
      const url = pdfBipUrl + `?${downloadBipOptions.join("&")}`
      window.open(url, "_blank").focus()
    }
  }
}
